import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {Api} from '../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {
  DEFAULT_WHITELABEL,
  findWhiteLabel,
  IWhiteLabel
} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {MixpanelService} from '../../services/mixpanel.service'
import {CredentialResponse} from "google-one-tap";
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';

declare const google: typeof import('google-one-tap');

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['../../../assets/css/login.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginRef') loginElement: ElementRef;

  validationActive:boolean=false;
  loginForm:FormGroup;
  busy:boolean = false;
  whitelabel_config: IWhiteLabel;
  auth2: any;
  loginError = null;

  constructor(private router:Router,
              public api:Api,
              private formBuilder:FormBuilder,
              private route:ActivatedRoute,
              private ngZone: NgZone,
              private mixpanel:MixpanelService) {
    this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
    localStorage.setItem('favicon',this.whitelabel_config.logos.favicon);
    localStorage.setItem('title',this.whitelabel_config.name);
    this.route.params.subscribe(params => {
      if (params['hash']) {
        this.api.addInitCallback(()=> {
          this.disable();
          this.api.hashLogin(params['hash']).then(success=> {
            if(this.api.logged)
              this.router.navigateByUrl('/dashboard');
            else
              this.enable();
          },error=>this.enable()).catch(error=>this.enable());
        });
      }
      if(params.partner_id) {
        this.whitelabel_config = findWhiteLabel(params.partner_id);
        localStorage.setItem('favicon',this.whitelabel_config.logos.favicon);
        localStorage.setItem('title',this.whitelabel_config.name);
      }
    });
    this.loginForm=formBuilder.group
    (
      {
        email:['',[Validators.required,Validators.email,Validators.pattern(environment.emailValidators)]],
        password:['',[Validators.required]]
      }
    );
  }

  ngOnInit() {
    this.initGoogle();
  }

  public disable() {
    this.busy=true;
    this.loginForm.controls.email.disable();
    this.loginForm.controls.password.disable();
  }

  public enable() {
    this.busy=false;
    this.loginForm.controls.email.enable()
    this.loginForm.controls.password.enable()
  }

  eventHandler(event) {
    if(event.keyCode==13)
      this.signIn();
  }

  public signIn()
  {
    this.validationActive = true;
    if(this.busy||!this.loginForm.valid) {
      return;
    }
    this.disable();
    const credentials = {
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    }
    this.api.login(credentials).pipe(
      tap(res => {
        this.enable();
        this.api.setUser(res.user);
        this.api.logged = true;
        this.mixpanel.peopleSetOnce({"Name": this.api.user.name, $email: this.api.user.email});
        this.mixpanel.identify(this.api.user.id.toString());
        this.router.navigateByUrl('/dashboard');
      }),
      catchError(err => {
        this.loginError = err.error.message;
        this.enable();
        this.api.logged = false;
        return of();
      })
    ).subscribe();
  }

  initGoogle() {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      google.accounts.id.renderButton(
        this.loginElement.nativeElement,
        { theme: 'outline', size: 'large', width: 225, logo_alignment: 'center' }
      );
      google.accounts.id.prompt();
    };
  }

  async handleCredentialResponse(response: CredentialResponse) {
    const credentials = {
      credential: response.credential,
      partner_id: this.whitelabel_config.id
    };
    this.api.googleAuthLogin(credentials).pipe(
      tap(res => {
        this.api.logged = true;
        this.api.setUser(res.user);
        this.router.navigateByUrl('/dashboard');
      }),
      catchError(err => {
        this.loginError = err.error.message;
        return of();
      })
    ).subscribe();
  }
}
