import {AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {Api} from '../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {CredentialResponse} from 'google-one-tap';
import {catchError, tap} from 'rxjs/operators';
import {of} from "rxjs";
import {Token} from "../../models/Authentication";
import {validationState} from "../../utilities/helpers";
import {findWhiteLabel, IWhiteLabel} from "@dollar-flight-club/shared_utilities/lib/utils/whitelabel";

declare var fbq:any;
declare var vgo: any;
declare var RH: any;
declare var liQ: any;
declare var SL: any;
declare var tdl: any;
declare const google: typeof import('google-one-tap');
declare var r: any;

@Component({
  selector: 'app-capture-form',
  templateUrl: './capture-form.component.html',
  styleUrls: ['./capture-form.component.css']
})
export class CaptureFormComponent implements OnInit, AfterViewInit {
  @ViewChild('email') emailElement: ElementRef;
  @ViewChild('password') passwordElement: ElementRef;
  @ViewChild('loginRef') loginElement: ElementRef;
  @Input() whitelabelConfig: IWhiteLabel;

  showPassword = false;
  captureForm:FormGroup;
  loading = false;
  liveConnect = 'travel2021';
  financeBuzz = 'lifetimefinancebuzz';
  source: string;
  medium: string;
  errorMsg = null;
  rfsn: boolean = false
  validationState = validationState;

  constructor(public api: Api,
              private formBuilder: FormBuilder,
              private router: Router,
              private ngZone: NgZone,
              private route: ActivatedRoute) {

    const emailValidators = [Validators.required, Validators.email, Validators.pattern(environment.emailValidators)];

    this.captureForm = formBuilder.group({
      email: ['', emailValidators],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if(params.rfsn){
        this.rfsn = true
      }
      if(params.utm_source || params.utm_medium) {
        this.source = params.utm_source;
        this.medium = params.utm_medium;
      }
      if(params.email){
        this.captureForm.controls.email.setValue(params.email)
      }
    });
    this.captureForm.disable();
    this.initGoogle();
    this.api.addInitCallback(() => {
      this.captureForm.enable();
    });
  }

  ngAfterViewInit(){
    this.emailElement.nativeElement.focus();
  }

  handleKeyDown(event) {
    if(event.keyCode===13){
      this.onSignupClick();
    }
  }

  onSignupClick(){
    if(this.captureForm.controls.email.valid && this.captureForm.controls.password.pristine){
      this.showPassword = true;
      setTimeout(()=>{ // this will make the execution after the above boolean has changed
        this.passwordElement.nativeElement.focus();
      },0);
    }
    else if(this.captureForm.controls.email.valid && this.captureForm.controls.password.valid){
      this.loading = true;
      this.captureForm.disable();

      const userEmail = this.captureForm.controls.email.value;
      const options = {
        partnerId: this.whitelabelConfig.id,
        source: this.source,
        medium: this.medium,
        rfsn: this.rfsn
      };
      // /dfc/public/signup When clicked gets called
      this.api.signup(userEmail, this.captureForm.controls.password.value, options).pipe(
        tap(res => {
          this.processSignup(res.user, res.token);
        }),
        catchError(err => {
          console.log(err);
          this.loading = false;
          this.captureForm.enable();
          this.errorMsg = err.error.message;
          return of();
        })
      ).subscribe();
    }
  }

  setACEmail(userEmail: string): void {
    vgo('setEmail', userEmail);
    vgo('process');
    console.log('Active campaigns tracked',userEmail);
  }

  initGoogle() {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      google.accounts.id.renderButton(
        this.loginElement.nativeElement,
        { theme: 'outline', size: 'large', width: 225, logo_alignment: 'center' }
      );
      google.accounts.id.prompt();
    };
  }

  handleCredentialResponse(response: CredentialResponse) {
    const credentials = {
      credential: response.credential,
      partner_id: this.whitelabelConfig.id,
      source: this.source,
      medium: this.medium,
      rfsn: this.rfsn
    };
    this.loading = true;
    this.api.googleAuthSignUp(credentials).pipe(
      tap(res => {
        this.processSignup(res.user, res.token);
      }),
      catchError(err => {
        this.ngZone.run(() => {
          this.loading = false;
          this.errorMsg = err.error.message;

        })
        return of()
      })
    ).subscribe();
  }

  async processSignup(user: {id: number, email: string, partnerId: string}, token: Token){
    this.loading = true;
    this.api.userEmail = user.email.toLowerCase();
    try {
      this.setACEmail(user.email);
      if(this.rfsn) {
        const uniqueId = this.api.genUniqueId()
        const rfsn = {
          cart: uniqueId,
          id: localStorage.getItem("rfsn_v4_id"),
          url: window.location.href,
          aid: localStorage.getItem("rfsn_v4_aid"),
          cs: localStorage.getItem("rfsn_v4_cs")
        };
        r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
        await this.api.updateAccountInfo({cartId: uniqueId})
      }
          //BIONIC tracking
      fbq('track', 'AddToCart');
      if (RH && RH.trackReferral) {
        RH.trackReferral(user.email, function () {
        });
      }

      if (SL) {
        SL.trackSubscriber(user.email);
      }

      //Live Connect tracking
      if(this.whitelabelConfig.id === this.liveConnect){
        liQ = liQ || [];
        liQ.push({
          'event': 'conversion',
          'name': 'email_signup',
          'emailHash': user.email
        });
      }
      // Finance Buzz tracking
      if(this.whitelabelConfig.id === this.financeBuzz){
        tdl.convert({
          'goal_id': '5493',
          'adv_sub': 'dollarflightclub-js'
        });
      }
      this.loading = false;
    } catch(error) {
      this.loading = false;
    }
    await this.ngZone.run(() =>
      this.router.navigate([`signup/departure`], { queryParamsHandling: 'preserve' }));
  }
}
