<div class="dealcover">
  <div class="dealinfocover animated slideInRight">
    <div class="row loader-container" *ngIf="currentDeal==null; else dealInfo">
      <div class="col-12 my-5 text-center">
        <img src="../../../assets/svgs/puff-loader.svg" style="width: 70px;"/>
      </div>
    </div>
    <ng-template #dealInfo>
      <div class="dealinfo">
        <a (click)="close()" class="close"><img src="/assets/images/exit-btn.png" width="13%" aria-hidden="true">
        </a>
        <span class="summary">{{api.strings?.portal.date_sent}}
          {{ currentDeal.featured_deal.date | dateFormat }}</span>
        <div class="row" *ngIf="currentDeal">
          <div class="col-xl-6 col-lg-6 col-md-6 col-12 mobile-center mobile-margin-20 text-center">
            <div class="image-container">

              <img
                src="{{currentDeal.featured_deal.image? currentDeal.featured_deal.image :'/assets/images/city.jpg'}}"
                class="main" id="dealimage" />
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-12 pl-4 mobile-center" *ngIf="currentDeal?.featured_deal.type !== dealTypes.domestic">
            <div class="deal-card">
              <h1 class="font-weight-bold font-20">{{currentDeal.featured_deal.origin_city}} ({{currentDeal.featured_deal.origin_airport}})
                <i class="arrow_left-right_alt"></i> {{currentDeal.featured_deal.destination_city}} ({{currentDeal.featured_deal.destination_airport}})
              </h1>
              <h2 class="open-sans" *ngIf="currentDeal.featured_deal?.minPrice; else price" [ngClass]="this.whitelabel_config.css + '-color'"><span
                class="price-font">${{currentDeal.featured_deal?.minPrice?.toFixed(0)}}</span><div class="note" style="font-size: 14px;">
                {{api.strings?.portal.round_trip}}</div>
              </h2>
              <ng-template #price>
                <h2 class="open-sans" [ngClass]="this.whitelabel_config.css + '-color'"><span
                  class="price-font">${{currentDeal.featured_deal?.price?.toFixed(0)}}</span><div class="note" style="font-size: 14px;">
                  {{api.strings?.portal.round_trip}}</div>
                </h2>
              </ng-template>
              <ul class="dealdetails">
                <li class="font16"><b>{{api.strings?.portal.standard_fare}}:</b> <span><i class="fa fa-caret-up"></i>
                  ${{currentDeal.featured_deal.average.toFixed(0)}}</span></li>
                <li class="font16"><b>{{api.strings?.portal.your_savings}}:</b> <span><i class="fa fa-scissors"></i>
                  ${{currentDeal.featured_deal.savings.toFixed(0)}}</span></li>
              </ul>
              <div class="row" *ngIf="currentDeal?.featured_deal.flightClass === flightClass.Economy">
                <div class="d-inline-block mx-auto">
                  <a class="btn btn-accent mb-btn-center pointer" [ngClass]="'primary-btn-' + this.whitelabel_config.css" [href]="skyScannerLink" target="_blank" rel="noopener noreferrer">
                    <i class="fa fa-plane"></i> View on Skyscanner</a>
                  <p></p>
                </div>
                <div class="d-inline-block mx-auto my-lg-0 my-2">
                  <a class="btn btn-accent mb-btn-center pointer" [ngClass]="'primary-btn-' + this.whitelabel_config.css" [href]="googleFlightsLink" target="_blank" rel="noopener noreferrer">
                    <i class="fa fa-plane"></i> View on Google Flights</a>
                  <p></p>
                </div>
              </div>
              <div class="row" *ngIf="currentDeal.featured_deal?.flightClass === flightClass.Business || currentDeal?.featured_deal.flightClass === flightClass.PremiumEconomy">
                <div class="d-inline-block mx-auto">
                  <a class="btn btn-accent mb-btn-center pointer" [ngClass]="'primary-btn-' + this.whitelabel_config.css" [href]="tequilaDeepLink" target="_blank" rel="noopener noreferrer">
                    <i class="fa fa-plane"></i> View on Kiwi</a>
                  <p></p>
                </div>
                <div class="d-inline-block mx-auto my-lg-0 my-2">
                  <a class="btn btn-accent mb-btn-center pointer" [ngClass]="'primary-btn-' + this.whitelabel_config.css" [href]="tequilaGeneralLink" target="_blank" rel="noopener noreferrer">
                    <i class="fa fa-plane"></i> Other dates on Kiwi</a>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-12 mobile-center" *ngIf="currentDeal?.featured_deal.type === dealTypes.domestic">
            <div class="deal-card">
              <h1 class="font-weight-bold font-20">{{currentDeal.featured_deal.origin_city}} ({{currentDeal.featured_deal.origin_airport}}) <i
                class="arrow_left-right_alt"></i> {{currentDeal.featured_deal.destination_city}}
                ({{currentDeal.featured_deal.destination_airport}})</h1>
              <div
                style="display:flex;justify-content:space-around;align-items: center;flex-direction:row;border-bottom: 1px solid #333;">
                <h2 class="open-sans" [ngClass]="this.whitelabel_config.css + '-color'"><span
                  class="font16">$</span>{{currentDeal.featured_deal.price.toFixed(0)}}<div class="note">
                  {{api.strings?.portal.round_trip}}</div>
                </h2>
                <ul class="dealdetails" style="margin-top:10px;">
                  <li class="font16"><b>{{api.strings?.portal.standard_fare}}:</b> <span><i class="fa fa-caret-up"></i>
                    $ {{currentDeal.featured_deal.average.toFixed(0)}}</span></li>
                  <li class="font16"><b>{{api.strings?.portal.your_savings}}:</b> <span><i class="fa fa-scissors"></i>
                    $ {{currentDeal.featured_deal.savings.toFixed(0)}}</span></li>
                </ul>
              </div>
              <div class="row mt-4">
                <div class="d-inline-block mx-auto">
                  <a class="btn btn-accent mb-btn-center pointer" [ngClass]="'primary-btn-' + this.whitelabel_config.css" [href]="skyScannerLink">
                    <i class="fa fa-plane"></i> View on Skyscanner</a>
                  <p></p>
                </div>
                <div class="d-inline-block mx-auto my-lg-0 my-2">
                  <a class="btn btn-accent mb-btn-center pointer" [ngClass]="'primary-btn-' + this.whitelabel_config.css" [href]="googleFlightsLink">
                    <i class="fa fa-plane"></i> View on Google Flights</a>
                  <p></p>
                </div>
              </div>
              <!-- <div style="display:flex;justify-content:flex-start;align-items:flex-start;flex-direction:column;">
                <a style="align-self: center;margin: 1.5em 0px;cursor:pointer" class="btn btn-accent square" [ngClass]="'primary-btn-' + this.whitelabel_config.css"
                (click)="openLinks()" target="_blank"><i class="fa fa-plane"></i> View Flight</a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="clear-20"></div>
        <div class="clear-20"></div>
        <div class="row mt-5 mt-md-0" *ngIf="currentDeal">
          <div class="col-12" *ngIf="currentDeal?.featured_deal.type !== dealTypes.domestic">
            <h3><i class="fa fa-plane"></i> {{currentDeal.featured_deal?.destination_city}}</h3>
            <div [innerHTML]="currentDeal?.featured_deal?.description"></div>
          </div>
        </div>
        <div class="clear-20"></div>
        <div class="row clear-20" *ngIf="currentDeal && api.premium">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mobile-margin-20" *ngIf="currentDeal?.featured_deal.type !== dealTypes.domestic">
            <div class="card-dep card-shadow">
              <h3><i class="icon_pin_alt"></i> From Your Airports </h3>
              <ul class="departures" *ngIf="currentDeal!=null">
                <li *ngFor="let deal of currentDeal.deals">
                  <a [href]="getSkyscannerLink(deal)" target="_blank" rel="noopener">{{deal.origin_city}}
                    ({{deal.origin_airport}}) <i class="arrow_left-right_alt"></i> {{deal.destination_city}}
                    ({{deal.destination_airport}})
                  </a>
                  <span class="price" *ngIf="deal.price > 0; else minPrice" [ngClass]="this.whitelabel_config.css + '-color'">${{deal.price.toFixed(0)}}</span>
                  <ng-template #minPrice>
                    <span class="price" [ngClass]="this.whitelabel_config.css + '-color'">${{deal.minPrice.toFixed(0)}}</span>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-12" [ngClass]="{'col-md-12':currentDeal?.featured_deal.type== dealTypes.domestic}">
            <div class="card-travel card-shadow xs-card" [ngClass]="{'mb-3':currentDeal?.featured_deal.type==dealTypes.domestic}">
              <h3><i class="icon_calendar"></i> Travel Dates</h3>
              <p>{{api.strings?.portal.availability_by_origin}} <span
                *ngIf="currentDeal&& currentDeal.dates.length>0">{{api.strings?.portal.play_dates_between}}</span>
              </p>
              <ul class="dates">
                <li *ngFor="let date of currentDeal.dates"><i class="fa fa-calendar"></i> ({{date.code}}) -
                  {{date.from_date | dateFormat}} and {{date.to_date | dateFormat}}</li>
              </ul>
            </div>
          </div>
          <div class="col-12 d-flex clear-20" *ngIf="currentDeal?.featured_deal?.type==1">
            <div class="card-notes card-shadow">
              <h3><img class="fa fa-fw grey-filter" src="https://dfcmedia.s3.amazonaws.com/email-assets/mask.svg"/> Travel Insurance</h3>
              <p>Insurance has never been more important for travelers whether you travel once a year or 10. We have teamed up with <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5698478597&iu=/22379081962">Travel Insured</a> to make sure you're covered for whatever your 2021 adventure throws at you. Travel relaxed, travel secure, and <a href="http://pubads.g.doubleclick.net/gampad/clk?id=5698478597&iu=/22379081962">Travel Insured</a>.</p>
              <div class="row">
                <div class="col-12 col-sm-9 pr-5">
                  <a [ngClass]="this.whitelabel_config.css + '-color'" href="http://pubads.g.doubleclick.net/gampad/clk?id=5698478597&iu=/22379081962" target="_blank">
                    <b>Purchase a policy today!</b>
                  </a>
                </div>
                <div class="col-12 col-sm-3 mt-4 mt-sm-0 text-center text-sm-right">
                  <a [ngClass]="this.whitelabel_config.css + '-color'" href="http://pubads.g.doubleclick.net/gampad/clk?id=5698478597&iu=/22379081962" target="_blank">
                    <img class="w-auto mt-1 mw-25-vh float-sm-right img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/til-logo.png"/>
                  </a>
                </div>
              </div>
            </div>
            <div class="clear-20"></div>
          </div>
          <div class="col-12" *ngIf="currentDeal?.featured_deal.type== dealTypes.domestic">
            <div class="card-travel card-shadow mb-3 xs-card">
              <h3><i class="icon_info_alt"></i> Important Notes</h3>
              <p class="small">{{api.strings?.portal.important_things_text}}</p>
            </div>
          </div>
        </div>

        <div class="clear-20" *ngIf="currentDeal && (currentDeal.deals.length>1 || !api.premium)"></div>
        <div class="row" *ngIf="currentDeal && (currentDeal.deals.length>1 || !api.premium)">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mobile-margin-20" *ngIf="currentDeal?.featured_deal?.type !== dealTypes.domestic">
            <div class="card-dep card-shadow">
              <h3><i class="icon_pin_alt"></i>
                <span *ngIf="api.premium"> Want to bring a friend? </span>
                <span *ngIf="!api.premium"> Other departures </span> </h3>
              <ul class="departures" *ngIf="currentDeal!=null">
                <li *ngFor="let deal of currentDeal.deals">
                  <a [href]="getSkyscannerLink(deal)" target="_blank" rel="noopener">
                    {{deal.origin_city}} ({{deal.origin_airport}})
                    <i class="arrow_left-right_alt"></i> {{deal.destination_city}} ({{deal.destination_airport}})
                  </a>
                  <span class="price" [ngClass]="this.whitelabel_config.css + '-color'">
                    ${{deal.price.toFixed(0)}}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="card-travel card-shadow mb-3 xs-card">
              <h3><i class="icon_calendar"></i> {{api.strings?.portal.travel_dates}}</h3>
              <p>{{api.strings?.portal.availability_by_origin}} <span
                *ngIf="currentDeal&&currentDeal.dates.length>0">{{api.strings?.portal.play_dates_between}}</span>
              </p>
              <ul class="dates">
                <li *ngFor="let date of currentDeal.dates;let i=index;"><i class="fa fa-calendar"></i> ({{date.code}}) -
                  {{ date.from_date | dateFormat }} and {{ date.to_date | dateFormat }}</li>
              </ul>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" *ngIf="currentDeal?.featured_deal.type==dealTypes.domestic">
            <div class="card-travel card-shadow xs-card">
              <h3><i class="icon_info_alt"></i> {{api.strings?.portal.important_things_title}}</h3>
              <p class="small">{{api.strings?.portal.important_things_text}}</p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!api.premium">
          <div class="col-12 clear-20">
            <div class="alert alert-light large centered xs-card mb-0" role="alert">
              <div class="alert-text">
                <h1 class="bold">Don’t see your home airports?</h1>
                Try Premium to get all our best deals, only from your favorite airports.
                <div class="clear-20"></div>
                <a (click)="upgrade()" class="btn btn-accent round-btn" [ngClass]="'primary-btn-' + this.whitelabel_config.css"
                >Learn More</a>
              </div>
            </div>
          </div>
        </div>

        <div class="clear-20"></div>
        <div class="row" *ngIf="currentDeal">
          <div class="col-12" *ngIf="currentDeal?.featured_deal.type !== dealTypes.domestic">
            <div class="card-notes card-shadow">
              <h3><i class="icon_info_alt"></i> Important Notes</h3>
              <p class="small">{{api.strings?.portal.important_things_text}}</p>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </div>
</div>
