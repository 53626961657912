<footer class="footer">
  <div class="row bottom ml-xl-5">
    <div class="col-12 ml-xl-5">
      <a href="https://dollarflightclub.com/terms-of-use/" target="_blank">Terms of use |</a>
      <a href="https://dollarflightclub.com/privacy-policy/" target="_blank">Privacy Policy |</a>
      <a href="https://dollarflightclub.com/refund-policy/" target="_blank">Refund Policy |</a>
      <a href="https://dollarflightclub.com/advertising-policy/" target="_blank">Advertising Policy |</a>
      <a href="https://dollarflightclub.com/security/" target="_blank">Security Policy</a>
    </div>
    <div class="col-12 ml-xl-5">
      <p style="color: #1C1C1C;">Icons made by <a href="https://www.flaticon.com/authors/freepik" target="_blank" style="color: #1C1C1C;  text-transform: lowercase; font-weight: normal;">Freepik</a> from
        <a href="https://www.flaticon.com/" target="_blank" style="color: #1C1C1C; text-transform: lowercase; font-weight: normal;">www.flaticon.com</a></p>
      <div class="copyright">
        Copyright &copy; 2024 Dollar Flight Club
      </div>
    </div>
  </div>
</footer>

