import {Component, Input, OnInit} from '@angular/core';
import {Api} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {IDealPreviewDto} from '../../models/DealPreview';
import {LegacyPlans, Membership, MembershipName, Memberships} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {DealTypes, FlightClass} from '@dollar-flight-club/shared_utilities/lib/models/Deal';
import {CheckoutComponent} from "../checkout/checkout.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserModel} from "../../models/Authentication";


@Component({
    selector: 'latestdeals',
    templateUrl: './latestdeals.component.html',
    styleUrls: ['./latestdeals.component.css', '../../../assets/css/portal.css', '../../../assets/css/portal-responsive.css', '../../../assets/fonts/elegantfont.css']
})

export class LatestdealsComponent implements OnInit {

    loading = true;
    deals: IDealPreviewDto[];
    viewAll = false;
    dealsLoading = false;
    dealTypes: typeof DealTypes;
    dealsCount = 0;
    page = 0;
    pages = 0;
    user: UserModel
    whitelabel_config: any;
    dollarflightclub = 'dfc';
    membershipTypes = MembershipName;

    @Input('fullSize') fullSize = false;


    constructor(public api: Api, private router: Router, private route: ActivatedRoute, private modalService: NgbModal) {
        this.loading = true;
        this.deals = [];
        this.dealTypes = DealTypes;
    }

    ngOnInit() {
        this.api.user$.subscribe(user => {
            if (user) {
              this.user = user
              this.whitelabel_config = findWhiteLabel(user.partnerId);
            }
        });
        this.route.queryParams.subscribe(params => {
            if (params.dealType) {
                this.updateDealtype(parseInt(params.dealType));
            }
        });
        this.api.addInitCallback(() => {
            this.getLatestDeals();
        });
    }

    async getDeals(pageNum: number) {
        try {
            this.loading = true;
            const deals = await this.api.getDealsList(pageNum);
            this.deals = deals ? deals.results : [];
            if (this.deals.length > 0) {
                this.deals = deals.results;
                this.page = deals.page;
                this.dealsCount = deals.count;
                this.pages = Math.floor(deals.count / 24) + ((deals.count % 24 > 0 ? 1 : 0));
            }
            this.loading = false;

        } catch (error) {
            this.deals = [];
            this.loading = false;
        }
    }

    showAllDeals() {
        this.viewAll = !this.viewAll;
        if (this.viewAll) {
            this.getDeals(this.page);
        } else {
            this.getLatestDeals();
        }
    }

    goToPage(page: number) {
        this.loading = true;
        this.getDeals(page);
    }

    region(event: any) {
        if (event.target.checked) {
            this.api.updateRegion(event.target.value).then(success => {
                this.loading = true;
                this.getLatestDeals();
            }, error => {
                this.loading = false;
            }).catch(error => {
                this.loading = false;
            });
        }
    }

    updateDealtype(type: number) {
        this.api.dealType = type;
        this.dealsLoading = true;
        this.getLatestDeals();
    }

    async getLatestDeals() {
        this.loading = true;
        this.deals = (await this.api.getLatestDeals(this.api.dealType) || []);
        this.loading = false;
        this.dealsLoading = false;
    }

    showDeal(deal: any) {
        const membershipId = this.api.user.membershipId;
        const isDealInMembership = this.isAbleToSeeDeal(membershipId, deal.dealMemberships);

        if (!isDealInMembership) {
          const checkout = this.modalService.open(CheckoutComponent)
          const component = checkout.componentInstance as CheckoutComponent
          component.user = this.user
          component.onboardingRedirect = false
          component.location = 'Members Dashboard - Latest Deals Component'
        } else {
            this.router.navigate(['/dashboard', 'deals', deal.id]);
        }
    }

    isAbleToSeeDeal(membership: number, dealMemberships: number[]): boolean{
      if(LegacyPlans.includes(membership) && dealMemberships.includes(Memberships.premium || Memberships.premiumTrial)){
        return true;
      }
      return dealMemberships.includes(membership);
    }

    getDealImage(deal: any): any {
        return deal && deal.image !== '' ? deal.image : '../../../assets/images/city.jpg';
    }

    getDealRequiredMembershipType(deal: IDealPreviewDto): MembershipName {
        const first = deal.dealMemberships[0];
        return Membership.getMembershipName(first) as MembershipName;
    }

    getFlightClassText(flightClass: FlightClass|string): string{
        if(flightClass === FlightClass.Economy){
          return 'Economy';
        }else if(flightClass === FlightClass.PremiumEconomy){
          return 'Prem Econ';
        }else if(flightClass === FlightClass.Business){
          return 'Business';
        }else if(flightClass === FlightClass.FirstClass){
          return 'First';
        }
        return '';
    }
}
