import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Api, CONCIERGE_ORIGIN, PERKS_ORIGIN} from '../../services/api.service';
import {validationState} from '../../utilities/helpers';
import {UserModel} from '../../models/Authentication';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.css']
})
export class EmailModalComponent implements OnInit{
  @Input() origin: string;
  @Input() partnerId: string;
  @Input() coupon: string;
  @Input() userEmail: string;
  @Input() source: string;
  @Input() medium: string;
  @Input() redirect: boolean;
  emailForm: FormGroup;
  user: UserModel | boolean;
  button = 'join-btn';
  validationState = validationState;
  loading = false;

  constructor(private api: Api, private formBuilder: FormBuilder, private el: ElementRef, private modalService: NgbModal) {
    this.emailForm = this.formBuilder.group({
      email: this.formBuilder.control('', [
        Validators.required,
        Validators.email,
      ])
    });
  }

  ngOnInit(): void {
        if(this.userEmail){
          this.emailForm.get('email').setValue(this.userEmail);
        }
    }

  closeModal() {
    if (!(this.redirect === true && this.origin === CONCIERGE_ORIGIN)) {
      this.modalService.dismissAll();
    }
  }


  get validEmail(): boolean {
    return this.emailForm.get('email').valid;
  }
  getNavLogo(): string {
    if(this.origin === PERKS_ORIGIN) {
      return 'https://dfcapp.s3.amazonaws.com/DFC+Perks+Card+Logo.png';
    } else {
      return 'https://dfcapp.s3.amazonaws.com/Concierge+Logo+11202023+-+Dollar+Flight+Club+DFC+Media.png';
    }
  }
  getButtonColor(): string {
    return this.origin === PERKS_ORIGIN ? 'black' : '#3A86FF';
  }

  getModalOverlayColor(): string {
    return this.origin === PERKS_ORIGIN ? 'rgba(255, 255, 255, 0.93)' : '#ECF5F5';
  }

  checkoutSession() {
    this.loading = true;
    this.userEmail = this.emailForm.get('email').value;
    if (!this.userEmail) {
      return;
    }
    this.api.sendEmailToActiveCampaign(this.userEmail, this.origin);
    if (this.origin === PERKS_ORIGIN) {
      this.api.perkProductCheckout(this.userEmail, this.source, this.medium, this.partnerId, this.coupon)
        .subscribe((res) => {
          this.loading = false;
          window.location.href = res;
        });
    } else if (this.origin === CONCIERGE_ORIGIN) {
      this.api.travelProductCheckout(this.userEmail, this.partnerId, this.coupon, this.source, this.medium)
        .subscribe((res) => {
          this.loading = false;
          window.location.href = res;
        });
    }
  }
  getBorderRadius(): number {
    if (this.origin === PERKS_ORIGIN) {
      return 0;
    }
  }

}
