<app-countdown-banner [displaySalesBanner]="whitelabelConfig?.signup.banner" [banner_txt]="whitelabelConfig?.signup.banner_text">
</app-countdown-banner>
<div class="container-contact100">
  <div class="wrap-contact100">
    <div class="contact100-form validate-form"
         [ngClass]="{'pb-0':whitelabelConfig?.signup.banner, 'pb-sm-4':whitelabelConfig?.signup.banner}">
      <div>
        <a href="https://dollarflightclub.com/" target="_blank">
          <img [ngClass]="whitelabelConfig?.css + '-logo'" class="img-responsive"
               [src]="whitelabelConfig?.logos.main">
        </a>
      </div>
      <div class="inline-block text-left ml-1">
        <h3 class="px-2 font-weight-bold">Save up to $2,000 on your next adventure without lifting a finger.</h3>
        <h6 class="sub-title mt-10 pl-2">Get email alerts when we find flights between 60-90% off leaving your home airport.
        </h6>
      </div>
      <app-capture-form [whitelabelConfig]="whitelabelConfig"></app-capture-form>
    </div>
    <div class="contact100-more flex-col-c-m d-none d-md-flex"
         style="background-image: url('https://dfcmedia.s3.amazonaws.com/email-assets/welcome-img-v2.png');">
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <div class="font-weight-bold text-light-grey" style="padding-top: 30px; margin-bottom: -15px">FEATURED IN</div>
      </div>
    </div>
  <div class="row grey-bg xs-w py-4 pl-3 pl-sm-5 pl-lg-0">
    <div class="offset-lg-1"></div>
    <div class="col-2 col-md-1 ml-3 ml-sm-3 ml-md-3 ml-lg-0 mr-md-4 ml-xl-2">
      <a href="https://thepointsguy.com/news/this-subscription-service-will-send-you-flight-deals-for-weekend-getaways/" target="_blank">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/thepointsguy.png">
      </a>
    </div>
    <div class="col-2 col-md-1 ml-md-0 mr-md-4 ml-xl-2">
      <a href="https://www.fodors.com/news/deals/how-to-find-cheap-flights-and-discounts-on-fodors-guidebooks" target="_blank">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/fodors.png">
      </a>
    </div>
    <div class="col-2 col-md-1 ml-md-0 mr-md-4 ml-xl-2">
      <a href="https://www.afar.com/magazine/finally-a-flight-deal-service-for-domestic-weekend-getaways" target="_blank">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/afar.png">
      </a>
    </div>
    <div class="col-2 col-md-1 ml-md-0 mr-md-4 ml-xl-2">
      <a href="https://www.thrillist.com/news/nation/cheap-flights-to-dublin-november-2018" target="_blank">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/thrillist.png">
      </a>
    </div>
    <div class="col-2 col-md-1 ml-md-0 mr-md-4 ml-xl-2">
      <a href="https://www.forbes.com/sites/jqlouise/2019/11/01/dollar-flight-club-launches-business-and-first-class-deals/?sh=30db175363c2" target="_blank">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/forbes.png">
      </a>
    </div>
    <div class="col-2 col-md-1 ml-md-0 mr-md-4 ml-xl-2 d-none d-md-block">
      <a href="https://www.cntraveler.com/story/3-new-ways-to-upgrade-to-business-class-without-paying-full-price" target="_blank">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/condenasttraveler.png">
      </a>
    </div>
    <div class="col-2 col-md-1 ml-md-0 mr-md-4 ml-xl-2 d-none d-md-block">
      <a href="https://www.cnn.com/2019/08/26/cnn-underscored/dollar-flight-club-premium-subscription/index.html" target="_blank">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/cnn.png">
      </a>
    </div>
    <div class="col-2 col-md-1 ml-md-0 mr-md-4 ml-xl-2 d-none d-md-block">
      <a href="https://www.click2houston.com/travel/2019/11/06/want-cheap-airfare-new-travel-club-offers-premium-discounts/" target="_blank">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/nbc.png">
      </a>
    </div>
    <div class="offset-lg-1"></div>
  </div>
</div>
<div class="overflow-x-hidden" *ngIf="whitelabelConfig?.signup.partner_view">
  <div class="container-fluid mt-5 px-1 px-sm-5 mb-5">
    <div class="row">
      <div class="col-12 text-center">
        <p class="font-weight-bold text-light-grey">WE DO THE HARD WORK FOR YOU</p>
        <h2 class="font-weight-bold">HERE'S HOW IT WORKS</h2>
      </div>
    </div>
    <div class="row mt-5 mb-4">
      <div class="col-12 col-md-4 px-4 text-center">
        <img class="w-auto h-15-vh img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/diary.png">
        <h5 class="font-weight-bold mt-3">Set your departure airports</h5>
        <p class="px-3">Signup and select which airports you want deals from. We'll do the rest.</p>
      </div>
      <div class="col-12 col-md-4 px-4 mt-3 mt-md-0 text-center">
        <img class="w-auto h-15-vh img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/transportation.png">
        <h5 class="font-weight-bold mt-3">Get email alerts when deals pop up</h5>
        <p class="px-3">Our team sends alerts when we find flights 60-90% off leaving your home airports.</p>
      </div>
      <div class="col-12 col-md-4 px-4 mt-3 mt-md-0 text-center">
        <img class="w-auto h-15-vh img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/travel.png">
        <h5 class="font-weight-bold mt-3">Book your dream trip</h5>
        <p class="px-3">Take advantage of one of the deals we share and save up to $500 when you book.</p>
      </div>
    </div>
  </div>
  <div class="grey-bg pb-5 pt-3 xs-w overflow-x-hidden">
    <div class="container-fluid mt-5 px-0 px-sm-5">
      <div class="row">
        <div class="col-12 text-center">
          <p class="font-weight-bold px-3 px-sm-0 text-light-grey">GET THE WORLD'S CHEAPEST FLIGHT DEALS DELIVERED TO YOUR INBOX</p>
          <h2 class="px-2 px-sm-0">AMAZING FLIGHTS LIKE THESE</h2>
        </div>
      </div>
      <div class="row mt-3 px-5">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-4">
          <div class="deal-card">
            <img class="img-fluid img-top-round" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-greece.png">
            <div class="px-3 py-2">
              <p>Greece</p>
              <p><s>$980</s></p>
              <p class="mb-0 alt-color font-weight-bold">$298 Roundtrip</p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-4">
          <div class="deal-card">
            <img class="img-fluid img-top-round" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-hawaii.jpg">
            <div class="px-3 py-2">
              <p>Hawaii</p>
              <p><s>$500</s></p>
              <p class="mb-0 alt-color font-weight-bold">$99 Roundtrip</p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-4">
          <div class="deal-card">
            <img class="img-fluid img-top-round"
                 src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-switzerland.png">
            <div class="px-3 py-2">
              <p>Switzerland</p>
              <p><s>$1020</s></p>
              <p class="mb-0 alt-color font-weight-bold">$325 Roundtrip</p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-4">
          <div class="deal-card">
            <img class="img-fluid img-top-round"
                 src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-miami.jpg">
            <div class="px-3 py-2">
              <p>Miami</p>
              <p><s>$430</s></p>
              <p class="mb-0 alt-color font-weight-bold">$109 Roundtrip</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mb-5 mt-5 pt-3 px-4per">
  <div class="row">
    <div class="col-12">
      <p class="text-center text-light-grey font-weight-bold">YOU DESERVE A VACATION</p>
      <h2 class="text-center">JOIN <span>1,000,000+</span> HAPPY TRAVELERS</h2>
    </div>
  </div>
  <div class="row px-0 pl-3 px-sm-5 mx-auto mt-4">
    <div class="col-12 col-sm-2 col-md-1">
      <div class="testimonial-img mx-auto mt-2 mb-3 mb-sm-0"
           style="background: url(https://dfcmedia.s3.amazonaws.com/email-assets/testimonial+1.png);"></div>
    </div>
    <div class="col-12 col-sm-10 col-md-5 pl-md-5 pl-lg-3 pl-xl-3 text-center text-sm-left fs-14" style="color: #555555">
      <p style="font-weight: 400">"I got a flight to Morocco and to Porto, Portugal for $480 round trip with luggage! Unreal.
        This flight would usually be $950+ depending on the time of the year. Great service!"</p>
      <p style="font-weight: 400">- Michael S.</p>
    </div>
    <div class="col-12 col-sm-2 col-md-1">
      <div class="testimonial-img mx-auto mt-2 mb-3 mb-sm-0"
           style="background: url(https://dfcmedia.s3.amazonaws.com/email-assets/testimonial+2.png);"></div>
    </div>
    <div class="col-12 col-sm-10 col-md-5 pl-md-5 pl-lg-3 pl-xl-3 text-center text-sm-left fs-14" style="color: #555555">
      <p style="font-weight: 400">"I booked flights to Iceland for $350 round trip and cruised the country searching for all of its beauties.
        Easily saved over $500. Such a great membership, paid for itself with my first flight.”</p>
      <p style="font-weight: 400">- Lauren N.</p>
    </div>
  </div>
  <div class="row px-0 pl-3 px-sm-5 mx-auto mt-4">
    <div class="col-12 col-sm-2 col-md-1">
      <div class="testimonial-img mx-auto mt-2 mb-3 mb-sm-0"
           style="background: url(https://dfcmedia.s3.amazonaws.com/email-assets/testimonial+3.png);"></div>
    </div>
    <div class="col-12 col-sm-10 col-md-5 pl-md-5 pl-lg-3 pl-xl-3 text-center text-sm-left fs-14" style="color: #555555; font-weight: 400">
      <p style="font-weight: 400">"How could I not book this Business class flight to Costa Rica for $542 roundtrip????? Saved me over
        $1,500 on airfare! Absolutely an amazing deal, I did it, and I'm super excited. your premium newsletter is f$%#@g awesome"</p>
      <p style="font-weight: 400">- Tahsan M.</p>
    </div>
    <div class="col-12 col-sm-2 col-md-1">
      <div class="testimonial-img mx-auto mt-2 mb-3 mb-sm-0"
           style="background: url(https://dfcmedia.s3.amazonaws.com/email-assets/testimonial+4.png);"></div>
    </div>
    <div class="col-12 col-sm-10 col-md-5 pl-md-5 pl-lg-3 pl-xl-3 text-center text-sm-left fs-14" style="color: #555555; font-weight: 400">
      <p style="font-weight: 400">"Dollar Flight Club, I can't thank you enough! I have been watching fares to Sydney like a hawk for nearly 25 years
        and this is the absolute lowest I've ever seen from my part of the country. Your service is the real deal! Keep doing what you do!"</p>
      <p style="font-weight: 400">- Susan P.</p>
    </div>
  </div>
</div>
