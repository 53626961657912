import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Api } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { findWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import { LegacyPlans, Membership, MembershipName, Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { DealTypes, FlightClass } from '@dollar-flight-club/shared_utilities/lib/models/Deal';
import { CheckoutComponent } from "../checkout/checkout.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
var LatestdealsComponent = /** @class */ (function () {
    function LatestdealsComponent(api, router, route, modalService) {
        this.api = api;
        this.router = router;
        this.route = route;
        this.modalService = modalService;
        this.loading = true;
        this.viewAll = false;
        this.dealsLoading = false;
        this.dealsCount = 0;
        this.page = 0;
        this.pages = 0;
        this.dollarflightclub = 'dfc';
        this.membershipTypes = MembershipName;
        this.fullSize = false;
        this.loading = true;
        this.deals = [];
        this.dealTypes = DealTypes;
    }
    LatestdealsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.api.user$.subscribe(function (user) {
            if (user) {
                _this.user = user;
                _this.whitelabel_config = findWhiteLabel(user.partnerId);
            }
        });
        this.route.queryParams.subscribe(function (params) {
            if (params.dealType) {
                _this.updateDealtype(parseInt(params.dealType));
            }
        });
        this.api.addInitCallback(function () {
            _this.getLatestDeals();
        });
    };
    LatestdealsComponent.prototype.getDeals = function (pageNum) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deals, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.api.getDealsList(pageNum)];
                    case 1:
                        deals = _a.sent();
                        this.deals = deals ? deals.results : [];
                        if (this.deals.length > 0) {
                            this.deals = deals.results;
                            this.page = deals.page;
                            this.dealsCount = deals.count;
                            this.pages = Math.floor(deals.count / 24) + ((deals.count % 24 > 0 ? 1 : 0));
                        }
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.deals = [];
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LatestdealsComponent.prototype.showAllDeals = function () {
        this.viewAll = !this.viewAll;
        if (this.viewAll) {
            this.getDeals(this.page);
        }
        else {
            this.getLatestDeals();
        }
    };
    LatestdealsComponent.prototype.goToPage = function (page) {
        this.loading = true;
        this.getDeals(page);
    };
    LatestdealsComponent.prototype.region = function (event) {
        var _this = this;
        if (event.target.checked) {
            this.api.updateRegion(event.target.value).then(function (success) {
                _this.loading = true;
                _this.getLatestDeals();
            }, function (error) {
                _this.loading = false;
            }).catch(function (error) {
                _this.loading = false;
            });
        }
    };
    LatestdealsComponent.prototype.updateDealtype = function (type) {
        this.api.dealType = type;
        this.dealsLoading = true;
        this.getLatestDeals();
    };
    LatestdealsComponent.prototype.getLatestDeals = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, this.api.getLatestDeals(this.api.dealType)];
                    case 1:
                        _a.deals = ((_b.sent()) || []);
                        this.loading = false;
                        this.dealsLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LatestdealsComponent.prototype.showDeal = function (deal) {
        var membershipId = this.api.user.membershipId;
        var isDealInMembership = this.isAbleToSeeDeal(membershipId, deal.dealMemberships);
        if (!isDealInMembership) {
            var checkout = this.modalService.open(CheckoutComponent);
            var component = checkout.componentInstance;
            component.user = this.user;
            component.onboardingRedirect = false;
            component.location = 'Members Dashboard - Latest Deals Component';
        }
        else {
            this.router.navigate(['/dashboard', 'deals', deal.id]);
        }
    };
    LatestdealsComponent.prototype.isAbleToSeeDeal = function (membership, dealMemberships) {
        if (LegacyPlans.includes(membership) && dealMemberships.includes(Memberships.premium || Memberships.premiumTrial)) {
            return true;
        }
        return dealMemberships.includes(membership);
    };
    LatestdealsComponent.prototype.getDealImage = function (deal) {
        return deal && deal.image !== '' ? deal.image : '../../../assets/images/city.jpg';
    };
    LatestdealsComponent.prototype.getDealRequiredMembershipType = function (deal) {
        var first = deal.dealMemberships[0];
        return Membership.getMembershipName(first);
    };
    LatestdealsComponent.prototype.getFlightClassText = function (flightClass) {
        if (flightClass === FlightClass.Economy) {
            return 'Economy';
        }
        else if (flightClass === FlightClass.PremiumEconomy) {
            return 'Prem Econ';
        }
        else if (flightClass === FlightClass.Business) {
            return 'Business';
        }
        else if (flightClass === FlightClass.FirstClass) {
            return 'First';
        }
        return '';
    };
    return LatestdealsComponent;
}());
export { LatestdealsComponent };
