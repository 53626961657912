import { Component, OnInit, Input } from '@angular/core';
import { Api } from '../../services/api.service';
import * as _ from 'lodash';
import whitelabel, {DEFAULT_WHITELABEL, findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'static-footer',
  templateUrl: './static-footer.component.html',
  styleUrls: ['./static-footer.component.css']
})
export class StaticFooterComponent implements OnInit {
  @Input() fullFooter: boolean;
  whitelabel_config: any;

  constructor(public api:Api, private route: ActivatedRoute) { }

  ngOnInit() {
    this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
    this.api.user$.subscribe(user =>{
      if(user){
        this.whitelabel_config = findWhiteLabel(user.partnerId);
        this.fullFooter = this.whitelabel_config.members_dashboard.footer;
      }
    });
    this.route.params.subscribe(params => {
      if(params.partner_id){
        this.whitelabel_config = findWhiteLabel(params.partner_id);
        this.fullFooter = this.whitelabel_config.members_dashboard.footer;
      }
    });
  }

}
