import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Api, CONCIERGE_ORIGIN, PERKS_ORIGIN } from '../../services/api.service';
import { validationState } from '../../utilities/helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var EmailModalComponent = /** @class */ (function () {
    function EmailModalComponent(api, formBuilder, el, modalService) {
        this.api = api;
        this.formBuilder = formBuilder;
        this.el = el;
        this.modalService = modalService;
        this.button = 'join-btn';
        this.validationState = validationState;
        this.loading = false;
        this.emailForm = this.formBuilder.group({
            email: this.formBuilder.control('', [
                Validators.required,
                Validators.email,
            ])
        });
    }
    EmailModalComponent.prototype.ngOnInit = function () {
        if (this.userEmail) {
            this.emailForm.get('email').setValue(this.userEmail);
        }
    };
    EmailModalComponent.prototype.closeModal = function () {
        if (!(this.redirect === true && this.origin === CONCIERGE_ORIGIN)) {
            this.modalService.dismissAll();
        }
    };
    Object.defineProperty(EmailModalComponent.prototype, "validEmail", {
        get: function () {
            return this.emailForm.get('email').valid;
        },
        enumerable: true,
        configurable: true
    });
    EmailModalComponent.prototype.getNavLogo = function () {
        if (this.origin === PERKS_ORIGIN) {
            return 'https://dfcapp.s3.amazonaws.com/DFC+Perks+Card+Logo.png';
        }
        else {
            return 'https://dfcapp.s3.amazonaws.com/Concierge+Logo+11202023+-+Dollar+Flight+Club+DFC+Media.png';
        }
    };
    EmailModalComponent.prototype.getButtonColor = function () {
        return this.origin === PERKS_ORIGIN ? 'black' : '#3A86FF';
    };
    EmailModalComponent.prototype.getModalOverlayColor = function () {
        return this.origin === PERKS_ORIGIN ? 'rgba(255, 255, 255, 0.93)' : '#ECF5F5';
    };
    EmailModalComponent.prototype.checkoutSession = function () {
        var _this = this;
        this.loading = true;
        this.userEmail = this.emailForm.get('email').value;
        if (!this.userEmail) {
            return;
        }
        this.api.sendEmailToActiveCampaign(this.userEmail, this.origin);
        if (this.origin === PERKS_ORIGIN) {
            this.api.perkProductCheckout(this.userEmail, this.source, this.medium, this.partnerId, this.coupon)
                .subscribe(function (res) {
                _this.loading = false;
                window.location.href = res;
            });
        }
        else if (this.origin === CONCIERGE_ORIGIN) {
            this.api.travelProductCheckout(this.userEmail, this.partnerId, this.coupon, this.source, this.medium)
                .subscribe(function (res) {
                _this.loading = false;
                window.location.href = res;
            });
        }
    };
    EmailModalComponent.prototype.getBorderRadius = function () {
        if (this.origin === PERKS_ORIGIN) {
            return 0;
        }
    };
    return EmailModalComponent;
}());
export { EmailModalComponent };
