import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {findWhiteLabel, IWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel'


declare global {
  interface Window {
    tdl:any;
  }
}
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})

export class SignUpComponent implements OnInit {
  whitelabelConfig: IWhiteLabel;
  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.whitelabelConfig = this.route.snapshot.data.whitelabel;
    localStorage.setItem('favicon',this.whitelabelConfig.logos.favicon);
    localStorage.setItem('title',this.whitelabelConfig.name);
    if (this.whitelabelConfig.id === 'lifetimefinancebuzz'){
      this.bootstrapFinanceBuzz();
    }
  }

  bootstrapFinanceBuzz() {

    var o = window.tdl = window.tdl || [];
    if (o.invoked) window.console && console.error && console.error("Tune snippet has been included more than once.");
    else {
      o.invoked = !0, o.methods = ["init", "identify", "convert"], o.factory = function(n) {
        return function() {
          var e = Array.prototype.slice.call(arguments);
          return e.unshift(n), o.push(e), o
        }
      };
      for (var e = 0; e < o.methods.length; e++) {
        var n = o.methods[e];
        o[n] = o.factory(n)
      }
      o.init = function(e) {
        var n = document.createElement("script");
        n.type = "text/javascript", n.async = !0, n.src = "https://js.go2sdk.com/v2/tune.js";
        var t = document.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(n, t), o.domain = e
      }
      o.init({
        'servetrack': 'https://servetrack.go2cloud.org'
      });
      o.identify();
      o.convert({
        'adv_sub': 'dollarflightclub-js'
      });
    }
  }
}
